<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-card>
          <b-card-text>
            <div class="row">
              <div class="col-md-12">
                <multiselect
                  v-model="form.warehouse"
                  track-by="id"
                  label="name"
                  placeholder="Seleccione un punto"
                  :options="warehouses"
                  :searchable="true"
                  :allow-empty="false"
                  @input="changeWarehouse()"
                >
                </multiselect>
              </div>
            </div>
            <b-table
              :items="items"
              :fields="fields"
              head-variant="light"
              caption-top
              small
            >
              <template #cell(is_active)="data">
                <toggle-button
                  v-model="data.item.is_active"
                  @change="deactivateItem(data.item)"
                  :value="data.item.is_active"
                  :labels="{ checked: 'ON', unchecked: 'OFF' }"
                  :color="{
                    checked: '#1BC5BD',
                    unchecked: '#F64E60',
                    disabled: '#CCCCCC',
                  }"
                />
              </template>
              <template #cell(actions)="data">
                <b-button
                  size="sm"
                  class="mb-2 mr-2"
                  variant="primary"
                  @click="editItem(data.item)"
                  title="Editar"
                >
                  <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                </b-button>
              </template>
            </b-table>
            <b-pagination
              v-model="page"
              :total-rows="rows"
              :per-page="perPage"
              @change="handlePageChange"
            ></b-pagination>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "ItemWarehouseList",

  data() {
    return {
      page: 1,
      rows: null,
      perPage: 10,
      form: {
        warehouse: null,
      },
      fields: [
        { key: "item_name", label: "Nombre" },
        { key: "warehouse_name", label: "Punto" },
        { key: "stock", label: "Stock" },
      ],
      items: [],
      warehouses: [],
    };
  },
  methods: {
    changeWarehouse() {
      const warehouse_id = this.form.warehouse.id;
      // Reset the table
      this.items = [];
      this.rows = null;
      this.currentPage = 1;
      this.getItems(this.currentPage, warehouse_id);
    },
    getItems(page = 1, warehouse_id = 1) {
      let slug = `?page=${page}&warehouse=${warehouse_id}`;
      ApiService.get("core/item-warehouse", slug).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.count;
      });
    },
    getWarehouses() {
      ApiService.get("core/warehouse", "?page_size=10000&page=1").then(
        (response) => {
          this.warehouses = response.data.results;
        }
      );
    },
    handlePageChange(value) {
      this.page = value;
      this.getItems(value);
    },
  },
  mounted() {
    this.getWarehouses();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Stock" }]);
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped></style>
